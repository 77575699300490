// Include variables and mixins
@import "./theme/variables";
@import "./theme/mixins";

// Custom Scss
@import "./theme/global";
@import "./theme/navigation";
@import "./theme/header";
@import "./theme/footer";
@import "./theme/cookie-alert";

// Import Bootstrap
@import "~bootstrap/scss/bootstrap";

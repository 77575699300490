// Font family

//$font-family-sans-serif: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
//$font-family-base:       $font-family-sans-serif;

/* $headings-font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; */
$headings-font-weight: 600;
/* 
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem; */

$enable-responsive-font-sizes: true;

// Color variations

$primary: #001f3f;
$secondary: #414141;

$white: #fff;
$offwhite: #f7f7f7;
$gray-100: $offwhite;
$black: $secondary;
//$black		  	: #222;

$h-line-color: lightgrey;

// Navbar
$navbar-bg-color: $secondary;

// Image overlay
$img-overlay-color: $black;
$img-overlay-opacity: 0.65;

// Footer
$footer-bg-color: $secondary;
$footer-font-color: $offwhite;

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
}

.bst-section {
  padding: 5em 0;
}

.bst-section-sm {
  padding: 1.5em 0 !important;
}

.bst-border-bottom {
  border-bottom: 1px solid $h-line-color;
}

.bst-border-top {
  border-top: 1px solid $h-line-color;
}

.bst-section-dark {
  background-color: $black;
}

.bst-section-primary {
  background-color: $primary;
}

.bst-section-primary,
.bst-section-dark {
  color: $offwhite;

  a:not(.btn),
  a:hover:not(.btn) {
    color: inherit;
  }

  .icon svg {
    fill: $offwhite;
  }
}

.bst-section-light {
  background-color: $offwhite;

  .btn-light {
    background-color: white;
  }
}

.bst-button-link {
  color: inherit !important;
  text-decoration: none !important;
}

.card-footer .btn-light {
  background-color: white;
}

.icon svg {
  height: 4em;
  width: 4em;
}

.icon-sm svg {
  height: 3em;
  width: 3em;
}

.illustration {
  height: 12em;
  width: auto;
}

.bst-hero-img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $img-overlay-color;
    opacity: $img-overlay-opacity;
    z-index: 1;
    transition: all 0.5s ease-out;
  }

  .overlay:hover {
    opacity: 0;
  }
}

.bst-hero-txt-left {
  width: 100%;
  padding: 15% 40% 15% 10%; //25%

  @include mobile-and-tablet-only {
    padding: 10%;
  }
}

.bst-hero-txt-right {
  width: 100%;
  padding: 15% 10% 15% 40%;

  @include mobile-and-tablet-only {
    padding: 10%;
  }
}

img.bst-retailer-logo {
  width: 10em;
}

.btn-autosize {
  @include large-devices-only {
    padding: 0.5rem 1rem !important;
    font-size: 1.25rem !important;
    line-height: 1.5 !important;
    border-radius: 0.3rem !important;
  }
}

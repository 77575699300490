.cookiealert {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  border-radius: 0 !important;
  color: $offwhite;
  background: $black;
}

.cookiealert a {
  color: $offwhite;
  text-decoration: underline;

  &:hover {
    color: darken($color: $offwhite, $amount: 7.5%);
  }
}

.cookiealert .cookiesbutton.cookiesbutton-dark {
  color: $offwhite;
}

.cookiealert .cookiesbutton {
  vertical-align: baseline;
}

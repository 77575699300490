header {
  position: relative;
  background-color: $black;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: $img-overlay-color;
    opacity: $img-overlay-opacity;
    z-index: 1;
  }

  .carousel-item,
  .bst-header-img {
    height: 65vh;
    min-height: 25vh;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .bst-header-img {
    height: 25vh;
  }

  .carousel-flex-container,
  .bst-header-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 75%;
    margin: 0 auto;
    overflow: hidden;
  }

  .carousel-caption,
  .bst-header-caption {
    color: $offwhite;
    text-align: center;
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 5;
  }
}

@mixin mobile-and-tablet-only {
  @media (max-width: 991px) {
    @content;
  }
}

@mixin large-devices-only {
  @media (min-width: 992px) {
    @content;
  }
}
